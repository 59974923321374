/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ReactPlayer from "react-player";

import qs from "qs";

// import vidImage from "assets/images/bg-sign-in-basic.jpeg";

// Dashboard components

import { useMaterialUIController } from "context";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // bgcolor: Item.backgroundColor,
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};

function Help() {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [videos, setVideos] = useState([]);
  const [vidurl, setVidurl] = useState("");
  const handleOpen = (url) => {
    // console.log(url);
    setVidurl(url);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const videoContBG = darkMode ? "#1A2027" : "#fff";
  const apiURL = process.env.REACT_APP_SITE_API;
  // console.log(apiURL);

  const query = {
    name: {
      equals: "Training",
    },
  };

  const stringifiedQuery = qs.stringify(
    {
      where: query,
    },
    { addQueryPrefix: true }
  );

  const url = `${apiURL}videolibrary${stringifiedQuery}`;

  // console.log(url);
  if (refresh) {
    setRefresh(false);
    fetch(url)
      .then((response) => response.json())
      .then((payload) => {
        // console.log(payload);
        setVideos(payload.docs[0].videos);
      });
  }

  const renderThumbnails = () => {
    const videoBoxes = [];
    if (videos.length > 0) {
      for (let i = 0; i < videos.length; i += 1) {
        // console.log(videos[i].subvideo);
        videoBoxes.push(
          <Grid item md={6} key={i}>
            <Item style={{ backgroundColor: videoContBG }}>
              <MDTypography
                variant="h3"
                fontWeight="bold"
                color="text"
                style={{ marginBottom: 10 }}
              >
                {videos[i].headline}
              </MDTypography>
              <MDTypography
                variant="h4"
                fontWeight="bold"
                color="text"
                style={{ marginBottom: 10 }}
              >
                {videos[i].subhead}
              </MDTypography>
              {/* eslint-disable */}
              <img
                src={videos[i].image.url}
                alt="video thumbnail"
                style={{ maxWidth: "80%", marginBottom: 20, cursor: "pointer" }}
                onClick={() => handleOpen(videos[i].link)}
                onKeyDown={() => handleOpen(videos[i].link)}
                role="button"
                tabIndex="0"
              />
              {/* eslint-enable */}
              <MDTypography
                style={{ cursor: "pointer" }}
                variant="h5"
                color="text"
                onClick={() => window.open(videos[i].subvideolink, "_blank")}
              >
                {videos[i].subvideo !== undefined ? videos[i].subvideo : <>&nbsp;</>}
              </MDTypography>
            </Item>
          </Grid>
        );
      }
      return <> {videoBoxes} </>;
    }
    return null;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} textAlign="center">
        <Grid container spacing={2}>
          {renderThumbnails()}
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style} textAlign="center">
          <MDButton onClick={handleClose} style={{ marginLeft: "90%" }}>
            X
          </MDButton>
          <div
            style={{
              position: "relative",
              // paddingTop: "36.25%",
              height: "80vh",
              width: "90vw",
              top: 0,
              left: 0,
              marginLeft: "-30px",
            }}
          >
            <ReactPlayer
              url={vidurl}
              controls
              className="ml-auto mr-auto"
              style={{ position: "absolute", top: 0, left: 0 }}
              width="100%"
              height="100%"
            />
          </div>
        </MDBox>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Help;
