/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ReactPlayer from "react-player";

// Dashboard components

import { useMaterialUIController } from "context";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // bgcolor: Item.backgroundColor,
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};

function Welcome() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // console.log(url);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const videoContBG = darkMode ? "#1A2027" : "#fff";
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} textAlign="center">
        <Grid item md={6} lg={12}>
          <Item style={{ backgroundColor: videoContBG }}>
            <MDTypography variant="h3" fontWeight="bold" color="text" style={{ marginBottom: 10 }}>
              Welcome to Cellphone Cash!
            </MDTypography>
            <MDTypography variant="h4" fontWeight="bold" color="text" style={{ marginBottom: 10 }}>
              Please watch the video below to get started.
            </MDTypography>
            {/* eslint-disable */}
            <img
              src="https://manage.cellphonecash.co/media/CPC-VT-PB.jpg"
              alt="video thumbnail"
              style={{ maxWidth: "80%", marginBottom: 20, cursor: "pointer" }}
              onClick={() => handleOpen()}
              onKeyDown={() => handleOpen()}
              role="button"
              tabIndex="0"
            />
            {/* eslint-enable */}
            <MDBox py={3} textAlign="left" sx={{ width: 570, mx: "auto" }}>
              <MDTypography variant="h5" color="text" style={{ marginBottom: 10 }}>
                Follow these steps to get started with Cell Phone Cash:
                <ol style={{ marginLeft: 40 }}>
                  <li>Watch the Welcome video on this page</li>
                  <li>Go to the Getting Started video training section</li>
                  <li>
                    Watch all of the Getting Started videos in order and follow the steps in them
                  </li>
                </ol>
                That’s all it takes to get things up and running
                <br />
              </MDTypography>
            </MDBox>
          </Item>
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style} textAlign="center">
          <MDButton onClick={handleClose} style={{ marginLeft: "90%" }}>
            X
          </MDButton>
          <div
            style={{
              position: "relative",
              // paddingTop: "36.25%",
              height: "80vh",
              width: "90vw",
              top: 0,
              left: 0,
              marginLeft: "-30px",
            }}
          >
            <ReactPlayer
              url="https://merlinholmes.wistia.com/medias/6laehq1c5k"
              controls
              className="ml-auto mr-auto"
              style={{ position: "absolute", top: 0, left: 0 }}
              width="100%"
              height="100%"
            />
          </div>
        </MDBox>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default Welcome;
