/* eslint-disable no-alert */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

import axios from "axios";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/white-paper-bg.jpg";

function Basic() {
  // const [rememberMe, setRememberMe] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const apiURL = process.env.REACT_APP_SITE_API;

  const signIn = useSignIn();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  /* eslint-disable */
  const onSubmit = () => {
    axios.post(`${apiURL}users/login`, formData).then((res) => {
      if (res.status === 200) {
        console.log("200!");
        if (
          signIn({
            token: res.data.token,
            expiresIn: 1440,
            tokenType: "Bearer",
            authState: res.data.message,
            // refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
            // refreshTokenExpireIn: res.data.refreshTokenExpireIn // Only if you are using refreshToken feature
          })
        ) {
          navigate("/survival");
        } else {
          console.log("not 200!");
          alert("login failed");
        }
      } else {
        console.log("not 200!");
      }
    }).catch(() => {
      alert(
        "Login Failed, please double check your email and password. If you need help, click the question mark in the lower right hand side of your display."
      );
    });
  };
  const onForgot = () => {
    // console.log(formData);
    if (formData.email === "") {
      alert(
        "Please enter your email address in the form and click Forgot Password again to reset your password."
      );
    } else {
      axios.get(`${apiURL.replace("/api/", "")}/lostpw?email=${formData.email}`).then((res) => {
        if (res.status === 200) {
          alert(
            "An email has been sent with instructions for resetting your password."
          );
        } else {
          alert(
            "There was a problem resetting your password, please double check your email and try again. If you continue having problems, please click the question mark in the lower right hand corner of your window, and contact support."
          );
        }
      }).catch(() => {
        alert(
          "There was a problem resetting your password, please double check your email and try again. If you continue having problems, please click the question mark in the lower right hand corner of your window, and contact support."
        );
      });
    }
  };
  /* eslint-enable */

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Cellphone Cash Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={() => onSubmit()} fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox textAlign="center" mt={5}>
              <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                Forget your password?
              </MDTypography>
              <MDTypography>Click below to request a reset.</MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="warning" onClick={() => onForgot()} fullWidth>
                forgot password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
