/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
// import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
// import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import { useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import qs from "qs";

import ReactPlayer from "react-player";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(127,127,127,.15)",
  // color: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(127,127,127,.15)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "rgba(127,127,127,.15)",
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // bgcolor: Item.backgroundColor,
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};

function Funnels() {
  const [open, setOpen] = useState(false);
  const [userFunnels, setUserFunnels] = useState([]);
  const [user, setUser] = useState({});
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("Select");
  const [cbid, setCbid] = useState("");
  const [refreshData, setRefreshData] = useState(true);
  const apiURL = process.env.REACT_APP_SITE_API;
  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const [editSite, setEditSite] = useState(false);
  const [editId, setEditId] = useState();
  const [spoofEmail, setSpoofEmail] = useState();

  const handleClose = () => setOpen(false);

  const setClickbankid = (id) => {
    let mId = id.toLowerCase();
    mId = mId.slice(0, 10);
    mId = mId.replace(/[^a-zA-Z0-9 ]/g, "");
    setCbid(mId);
  };

  // console.log(authHeader());

  // ("Authorization", `JWT ${this.props.authHeader.split(' ')[1]}`)
  const stringifiedQuery = qs.stringify(
    {
      limit: 200,
      mimic: spoofEmail,
      // Authorization: `JWT ${authHeader().split(" ")[1]}`,
    },
    { addQueryPrefix: true }
  );

  if (refreshData) {
    // console.log(categories);
    // setUserFunnels("test");
    let url = `${apiURL}users/me`;
    fetch(url, {
      headers: {
        Authorization: `JWT ${authHeader().split(" ")[1]}`,
      },
    })
      .then((response) => response.json())
      .then((payload) => {
        console.log(payload.user);
        if (payload.user === null) {
          navigate("/authentication/sign-in");
        }
        setUser(payload.user);
      });

    // setUserFunnels("test");
    url = `${apiURL}funnels${stringifiedQuery}`;
    fetch(url, {
      headers: {
        Authorization: `JWT ${authHeader().split(" ")[1]}`,
      },
    })
      .then((response) => response.json())
      .then((payload) => {
        console.log(payload);
        setUserFunnels(payload.docs);
      });

    // setCategories("test");
    url = `${apiURL}categories`;
    fetch(url)
      .then((response) => response.json())
      .then((payload) => {
        // console.log(payload.docs);
        setCategories(payload.docs);
      });

    setRefreshData(false);
  }

  const deleteFunnel = (id) => {
    const url = `${apiURL}funnels/${id}`;
    // console.log(url);
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `JWT ${authHeader().split(" ")[1]}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        // console.log(data);
        setRefreshData(true);
      });
  };

  const editFunnel = (curId, curCategory, curCbid) => {
    console.log(editId);
    console.log(curCategory);
    setEditId(curId);
    setCategory(curCategory.id);
    setCbid(curCbid);
    setEditSite(true);
  };

  const updateFunnel = () => {
    const data = {
      category,
      cbid,
    };
    // console.log(`category: ${category}`);
    // console.log(`cbid: ${cbid}`);
    const url = `${apiURL}funnels/${editId}`;
    // console.log(url);
    if (category !== "Select" && cbid !== "") {
      setEditSite(false);
      fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `JWT ${authHeader().split(" ")[1]}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data),
      })
        .then((response) => response.json())
        .then(() => {
          // console.log(temp);
          setRefreshData(true);
        });
    } else {
      alert("Please make sure to select a category, and enter a valid ClickBank Nickname.");
    }
  };

  const createFunnel = () => {
    const data = {
      category,
      cbid,
    };
    // console.log(`category: ${category}`);
    // console.log(`cbid: ${cbid}`);
    const url = `${apiURL}funnels/`;
    // console.log(url);
    if (category !== "Select" && cbid !== "") {
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: `JWT ${authHeader().split(" ")[1]}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data),
      })
        .then((response) => response.json())
        .then(() => {
          // console.log(temp);
          setRefreshData(true);
        });
    } else {
      alert("Please make sure to select a category, and enter a valid ClickBank Nickname.");
    }
  };

  const getSiteURL = (siteCategory) => {
    switch (siteCategory) {
      case "Natural Health":
        return "5bestnaturalremedies.com/?";
      case "Green Products":
        return "5bestgreenproducts.com/?";
      case "Spirituality":
        return "5bestspirituality.com/?";
      case "Weight Loss":
        return "5bestweightloss.com/?";
      case "Make Money":
        return "5bestmoneymakers.com/?";
      case "Betting Systems":
        return "5bestbettingsystems.com/?";
      case "Cooking Helpers":
        return "5bestcookinghelpers.com/?";
      case "Dating Products":
        return "5bestdatingproducts.com/?";
      case "Dental Products":
        return "5bestdentalhealthproducts.com/?";
      case "Survival Products":
        return "5bestsurvivalproducts.com/?";
      default:
        return "funnels.cellphonecash.co/?";
    }
  };

  const renderFunnels = () => {
    const funnels = [];
    if (user) {
      for (let i = 0; i < Math.min(userFunnels.length, user.funnelLimit); i += 1) {
        funnels.push(
          <Grid
            key={i}
            container
            rowSpacing={0}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{
              background: `linear-gradient(90deg, rgba(127, 127, 127, ${
                (i % 2) / 10
              }) 0%, rgba(127, 127, 127, 0) 100%`,
            }}
          >
            <Grid item md={2} mb={1} mt={1}>
              <MDTypography variant="h6">{userFunnels[i].category.category}</MDTypography>
            </Grid>
            <Grid item md={1} mb={1} mt={1}>
              <MDTypography variant="h6">{userFunnels[i].cbid}</MDTypography>
            </Grid>
            <Grid item md={5} mb={1} mt={1}>
              <MDTypography variant="h6">
                https://{getSiteURL(userFunnels[i].category.category)}id={userFunnels[i].id}
              </MDTypography>
            </Grid>
            <Grid item md={4} mb={1} mt={1}>
              <MDTypography variant="h6">
                <MDButton
                  size="small"
                  color="info"
                  onClick={() =>
                    editFunnel(userFunnels[i].id, userFunnels[i].category, userFunnels[i].cbid)
                  }
                >
                  Edit
                </MDButton>
                <MDButton
                  size="small"
                  color="info"
                  style={{ marginLeft: 20 }}
                  onClick={() =>
                    window.open(
                      `https://${getSiteURL(userFunnels[i].category.category)}id=${
                        userFunnels[i].id
                      }`,
                      "_blank"
                    )
                  }
                >
                  Preview
                </MDButton>
                <MDButton
                  size="small"
                  color="warning"
                  style={{ marginLeft: 20 }}
                  onClick={() => deleteFunnel(userFunnels[i].id)}
                >
                  Delete
                </MDButton>
              </MDTypography>
            </Grid>
          </Grid>
        );
      }
      return <> {funnels} </>;
    }
    return null;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style} textAlign="center">
          <MDButton onClick={handleClose} style={{ marginLeft: "90%" }}>
            X
          </MDButton>
          <div
            style={{
              position: "relative",
              paddingTop: "36.25%",
              marginLeft: "18%",
              marginRight: "18%",
            }}
          >
            <ReactPlayer
              url="https://merlinholmes.wistia.com/medias/z3k1eovs80"
              controls
              className="ml-auto mr-auto"
              style={{ position: "absolute", top: 0, left: 0 }}
              width="100%"
              height="100%"
            />
          </div>
          <MDButton
            style={{ marginTop: 20 }}
            size="large"
            color="success"
            onClick={() =>
              window.open(
                `https://link.cellphonecash.co/aff_ad?campaign_id=3218&email=${user.email}&hostNameId=22760&aff_id=1966&source=In-App_Purchasers&aff_sub=UpgradeNow&aff_sub2=button&aff_unique1={network}`,
                "_blank"
              )
            }
          >
            Upgrade Now
          </MDButton>
        </MDBox>
      </Modal>
      <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h4" gutterBottom>
              My Websites
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox m={3} p={2}>
          {renderFunnels()}
        </MDBox>
      </Card>
      {editSite > 0 && (
        <>
          <br />
          <Card style={{ marginBottom: 20 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDBox>
                <MDTypography variant="h4" gutterBottom>
                  Edit Website
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox m={0} p={2}>
              <MDInput
                select
                autoFocus
                value={category}
                label="Category"
                style={{ width: 120, marginRight: 20 }}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem key="Select" value="Select">
                  <MDBox style={{ paddingTop: 11, paddingBottom: 12 }}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Select
                    </MDTypography>
                  </MDBox>
                </MenuItem>
                {categories.map((option) => (
                  <MenuItem key={option.category} value={option.id}>
                    <MDBox style={{ paddingTop: 11, paddingBottom: 12 }}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {option.category}
                      </MDTypography>
                    </MDBox>
                  </MenuItem>
                ))}
              </MDInput>
              <MDInput
                style={{ marginRight: 20 }}
                type="text"
                label="ClickBank Nickname"
                value={cbid}
                onChange={(e) => setClickbankid(e.target.value)}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                id="cbid"
              />
              <Tooltip title="This is the ClickBank Nickname created at ClickBank.com">
                <Icon
                  fontSize="medium"
                  color="secondary"
                  style={{ marginLeft: -10, marginRight: 20 }}
                >
                  help
                </Icon>
              </Tooltip>
              <MDButton color="info" onClick={updateFunnel} style={{ marginRight: 20 }}>
                Update Website
              </MDButton>
              <MDButton color="warning" onClick={() => setEditSite(false)}>
                Cancel Edit
              </MDButton>
            </MDBox>
          </Card>
        </>
      )}
      {user.role === "admin" && (
        <>
          <br />
          <Card style={{ marginBottom: 20 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDBox>
                <MDTypography variant="h4" gutterBottom>
                  Admin Account Override
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox m={0} p={2}>
              <MDInput
                style={{ marginRight: 20 }}
                type="text"
                label="Email of user to mimic"
                value={spoofEmail}
                onChange={(e) => setSpoofEmail(e.target.value)}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                id="spoofEmail"
              />
              <MDButton onClick={() => setRefreshData(true)} color="info">
                Set User
              </MDButton>
            </MDBox>
          </Card>
        </>
      )}
      {userFunnels.length < user.funnelLimit && categories.length > 0 && (
        <>
          <br />
          <Card style={{ marginBottom: 20 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDBox>
                <MDTypography variant="h4" gutterBottom>
                  Create Website
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox m={0} p={2}>
              <MDInput
                select
                value={category}
                label="Category"
                style={{ width: 120, marginRight: 20 }}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem key="Select" value="Select">
                  <MDBox style={{ paddingTop: 11, paddingBottom: 12 }}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Select
                    </MDTypography>
                  </MDBox>
                </MenuItem>
                {categories.map((option) => (
                  <MenuItem key={option.category} value={option.id}>
                    <MDBox style={{ paddingTop: 11, paddingBottom: 12 }}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {option.category}
                      </MDTypography>
                    </MDBox>
                  </MenuItem>
                ))}
              </MDInput>
              <MDInput
                style={{ marginRight: 20 }}
                type="text"
                label="ClickBank Nickname"
                value={cbid}
                onChange={(e) => setClickbankid(e.target.value)}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                id="cbid"
              />
              <Tooltip title="This is the ClickBank Nickname created at ClickBank.com">
                <Icon
                  fontSize="medium"
                  color="secondary"
                  style={{ marginLeft: -10, marginRight: 20 }}
                >
                  help
                </Icon>
              </Tooltip>
              <MDButton onClick={createFunnel} color="info">
                Create Website
              </MDButton>
            </MDBox>
          </Card>
        </>
      )}
      {user.funnelLimit === 1 && (
        <>
          <br />
          <Card style={{ marginBottom: 20 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDBox style={{ maxWidth: 1000 }}>
                <MDTypography variant="h4" gutterBottom>
                  Upgrade Today To Increase Your Earning Power And Save 48%!
                </MDTypography>
                <MDTypography>
                  Take full advantage of the Cell Phone Cash Software and upgrade your account to a
                  5 website account! You’ll be able to create 5 websites in the 5 most profitable
                  markets on the Internet. The more websites you have the higher your earning
                  abilities will be, upgrade now and save!
                </MDTypography>
                <MDButton
                  style={{ marginTop: 20 }}
                  size="large"
                  color="success"
                  onClick={() => setOpen(true)}
                >
                  Upgrade Now
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </>
      )}
      <Card style={{ marginBottom: 20 }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox style={{ maxWidth: 1000 }}>
            <MDTypography variant="h4">
              FAQs
              <br />
              <br />
            </MDTypography>
            <Accordion>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <MDTypography>Why do I get an error when testing my new website?</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography>
                  This most likely indicates an issue with your ClickBank nickname/ID). Your first
                  step is to make sure that you entered your ClickBank Nickname properly when
                  creating your site. If that does not resolve the issue please review the “How To
                  Solve Problems With ClickBank” video in the Training section.
                </MDTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <MDTypography>
                  My ClickBank account was suspended or disabled, what do I do?
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography>
                  Please review the “How To Solve Problems With ClickBank” video in the Training
                  section.
                </MDTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <MDTypography>How Do I Get Paid?</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography>
                  Please refer to the&nbsp;
                  <a
                    href="https://support.clickbank.com/hc/en-us/articles/220609947-How-do-I-get-paid-"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#888" }}
                  >
                    Clickbank Support documention linked here.
                  </a>
                </MDTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <MDTypography>
                  Why Isn&apos;t Cellphone Cash website generating sales after I created it?
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography>
                  Traffic is not provided for your Cell Phone Cash website. We do provide a variety
                  of free training to give you the steps to follow. Please refer to the traffic
                  related training video lessons and then choose the method that you prefer to
                  promote your review site(s)
                </MDTypography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <MDTypography>Is there a fee to use ClickBank?</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDTypography>
                  Creating an affiliate account within Clickbank is free. You may be presented with
                  optional training (Spark) provided by Clickbank while signing up for your account.
                  Purchasing Spark is not necessary to use the Cellphone Cash service. The Spark
                  program does provide very good training and tools on being successful as an
                  affiliate marketer, but is not a required purchase by any means.
                </MDTypography>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Funnels;
